import { graphql } from "gatsby"
import React from "react"
import { AspectRatio, Box, Flex, Grid, Heading, Text } from "theme-ui"

import BlockContainer from "../components/block-container"
import ContentfulFooterBlock from "../components/contentful-footer-block"
import Layout from "../components/layout"
import SEO from "../components/seo"

const TeamMemberPage = ({ data }) => {
  const { contentfulTeamMemberPage: contentfulTeamMember, contentfulHomePage } =
    data
  const { headerLabel } = contentfulHomePage

  return (
    <>
      <SEO
        description={contentfulTeamMember.seoDescription?.seoDescription}
        title={contentfulTeamMember.seoTitle || contentfulTeamMember?.name}
      />
      <Layout headerLabel={headerLabel}>
        <BlockContainer noPaddings sxOverride={{ pb: 5, pt: 7 }}>
          <Flex
            sx={{
              alignItems: "center",
              flexDirection: ["column-reverse", "column-reverse", "row", "row"],
            }}
          >
            <Box sx={{ flex: 1, p: 4 }}>
              <Heading
                as="h1"
                sx={{ textAlign: ["center", "center", "right", "right"] }}
                variant="h1"
              >
                {contentfulTeamMember.name}
              </Heading>
              <Heading
                as="h3"
                sx={{
                  mt: 4,
                  textAlign: ["center", "center", "right", "right"],
                }}
                variant="h3"
              >
                {contentfulTeamMember.position}
              </Heading>
              <Box sx={{ mt: 3 }}>
                {contentfulTeamMember.tags?.map((tag, index) => (
                  <Text
                    key={`tag-${index}`}
                    sx={{
                      py: 1,
                      textAlign: ["center", "center", "right", "right"],
                    }}
                    as="p"
                    variant="caption1"
                  >
                    + {tag}
                  </Text>
                ))}
              </Box>
            </Box>
            <Box
              sx={{ flex: 1, p: 3, width: ["100%", "100%", "auto", "auto"] }}
            >
              <AspectRatio ratio={1 / 1}>
                <img
                  alt={contentfulTeamMember.name}
                  src={contentfulTeamMember.photo?.resize?.src}
                  style={{ height: "100%", objectFit: "cover", width: "100%" }}
                />
              </AspectRatio>
            </Box>
          </Flex>
        </BlockContainer>
        <BlockContainer noPaddings sxOverride={{ mt: 4 }}>
          <div
            dangerouslySetInnerHTML={{
              __html: `<iframe src="https://open.spotify.com/embed?uri=${contentfulTeamMember.spotifyPlaylistId}" width="100%" height="450px" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>`,
            }}
          />
        </BlockContainer>
        <BlockContainer noPaddings sxOverride={{ mb: 6, mt: 4 }}>
          <Grid columns={[1, 1, 2, 4]} gap={32}>
            {contentfulTeamMember.instagramPictures?.map(
              (instagramPicture, index) => (
                <Box key={`instagram-${index}`}>
                  <AspectRatio ratio={1 / 1}>
                    <img
                      alt=""
                      style={{
                        height: "100%",
                        objectFit: "cover",
                        width: "100%",
                      }}
                      src={instagramPicture?.resize?.src}
                    />
                  </AspectRatio>
                  <Text sx={{ mt: [2, 2, 3, 3] }}>
                    {instagramPicture?.description}
                  </Text>
                </Box>
              ),
            )}
          </Grid>
        </BlockContainer>
        <ContentfulFooterBlock
          address={contentfulTeamMember.footer?.address?.address}
          backgroundColor={contentfulTeamMember.footer?.backgroundColor}
          copyright={contentfulTeamMember.footer?.copyright?.copyright}
          facebookLink={contentfulTeamMember.footer?.facebookLink}
          form={contentfulTeamMember.footer?.form}
          gif={contentfulTeamMember.footer?.gIf}
          socialLinksTitle={contentfulTeamMember.footer?.socialLinksTitle}
          blogTitle={contentfulTeamMember.footer?.blogTitle}
          newsletterJoinTitle={contentfulTeamMember.footer?.newsletterJoinTitle}
          gifDescription={contentfulTeamMember.footer?.gifDescription}
          instagramLink={contentfulTeamMember.footer?.instagramLink}
          linkedInLink={contentfulTeamMember.footer?.linkedInLink}
        />
      </Layout>
    </>
  )
}

export const query = graphql`
  query TeamMember($slug: String!) {
    contentfulHomePage {
      headerLabel
    }
    contentfulTeamMemberPage(slug: { eq: $slug }) {
      footer {
        address {
          address
        }
        backgroundColor
        copyright {
          copyright
        }
        blogTitle
        newsletterJoinTitle
        socialLinksTitle
        facebookLink
        gIf {
          file {
            url
          }
          resize(width: 35) {
            src
          }
        }
        gifDescription
        instagramLink
        linkedInLink
      }
      instagramPictures {
        description
        resize(width: 1000) {
          src
        }
      }
      name
      photo {
        resize(width: 1000) {
          src
        }
      }
      position
      seoTitle
      seoDescription {
        seoDescription
      }
      slug
      spotifyPlaylistId
      tags
    }
  }
`

export default TeamMemberPage
